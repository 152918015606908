import React from 'react';
import PropTypes from 'prop-types';
import { APP_PAGES } from '../../config/const.config';
import logoImg from '../../assets/images/logo.png';
import searchIcon from '../../assets/images/icons/search.png';
import scrollAppView from '../../utils/viewScroll.util';

const TopMenu = ({
  menuData,
  activePage,
  handlePageChange,
  activePageLayoutType,
}) => {
  const appName = process.env.REACT_APP_NAME;
  const menuDataLength = menuData.length;

  // const navBarScroll = () => {
  //   const focusedElements = window.document.querySelectorAll(
  //     '.prj-element.focused'
  //   );
  //   console.log(focusedElements, '*****from Topnav');
  //   // const activeTab = focusedElements[0].id;
  //   if (focusedElements) {
  //     window.document.querySelectorAll(
  //       '.page-container .page-content.has-bg-video'
  //     )[0].style.paddingTop = '540px';
  //     window.document.querySelectorAll(
  //       '.page-container .page-content.has-bg-video'
  //     )[0].style.marginTop = '0';
  //   } else {
  //     window.document.querySelectorAll(
  //       '.page-container .page-content.has-bg-video'
  //     )[0].style.paddingTop = '540px';
  //     window.document.querySelectorAll(
  //       '.page-container .page-content.has-bg-video'
  //     )[0].style.marginTop = '0';
  //   }
  // };

  const handleScroll = () => {
    const focusedElements = window.document.querySelectorAll(
      '.prj-element.focused'
    );
    if (focusedElements.length > 0) {
      scrollAppView(focusedElements[0]);
      if (
        // window.document.querySelector('.live-video-container') &&
        activePageLayoutType !== 'grid' &&
        activePage !== 'search'
      ) {
        if (focusedElements[0].id === 'top-menu-0') {
          if (window.document.querySelector('.live-video-container')) {
            if (window.document.querySelectorAll('.page-container')) {
              window.document.querySelectorAll(
                '.page-container'
              )[0].style.marginTop = `788px`;
            }
            // if (
            //   window.document.querySelectorAll(
            //     '.page-container .page-content.has-bg-video'
            //   )
            // ) {
            //   window.document.querySelectorAll(
            //     '.page-container .page-content.has-bg-video'
            //   )[0].style.marginTop = '5px';
            // }
          }
        }
      }
    }
  };

  return (
    <nav className="nav top-navigation" id="top-navigation">
      <button
        type="button"
        className="hide"
        id="top-bar-tabs"
        onClick={handleScroll}
      >
        Scroll
      </button>
      <div className="nav-left menu-links">
        <a className="link brand-logo">
          <img src={logoImg} alt={appName} />
        </a>
        <div
          className="tabs"
          // onClick={() => navBarScroll()}
          role="none"
          id="top-bar-tabs"
        >
          {menuData.map((item, idx) => (
            <a
              id={`top-menu-${idx}`}
              className={`link menu-item prj-element ${
                activePage === item.id.toString() ? 'active focused' : ''
              }`}
              key={`menu-${item.id}`}
              aria-hidden
              onClick={() => handlePageChange(item.id)}
              data-focus-left={idx === 0 ? false : `#top-menu-${idx - 1}`}
              data-focus-right={
                idx + 1 === menuDataLength
                  ? '#top-menu-search'
                  : `#top-menu-${idx + 1}`
              }
              data-focus-up={false}
              data-focus-down=".page-container .prj-element"
              data-on-self-focus="#top-bar-tabs"
            >
              {item.title}
            </a>
          ))}
        </div>
      </div>
      <div className="nav-right">
        <a
          id="top-menu-search"
          className={`link icon prj-element ${
            activePage === APP_PAGES.SEARCH ? 'active focused' : ''
          }`}
          aria-hidden
          onClick={() => handlePageChange(APP_PAGES.SEARCH)}
          data-focus-left={`#top-menu-${menuDataLength - 1}`}
          data-focus-right="#top-menu-settings"
          data-focus-up={false}
          data-focus-down="input#query.prj-element.input-class"
        >
          <img src={searchIcon} alt="Search" />
        </a>
      </div>
    </nav>
  );
};

TopMenu.propTypes = {
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ).isRequired,
  activePage: PropTypes.string.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  activePageLayoutType: PropTypes.string.isRequired,
};

export default TopMenu;
