/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import DetailLayout from '../../layout/Detail';
import Player from '../common/Player';
import { getUserVideoProgress } from '../../utils/localCache.util';
import convertSecToTime from '../../utils/timeformat.util';
import playIcon from '../../assets/images/icons/play.png';
import resumeIcon from '../../assets/images/icons/resume.png';
import restoreFocus from '../../utils/restoreFocus';
import scrollAppView from '../../utils/viewScroll.util';

const DetailPage = ({
  detailPageData,
  handleHideDetailPage,
  videos,
  containerIdPrefix,
  setShowDetailPage,
}) => {
  const [showPlayer, setShowPlayer] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [showPlayerFromProgress, setShowPlayerFromProgress] = useState(false);
  const scrollHandleButtonId = `scroll-element-${containerIdPrefix}`;
  const moreLikeThis = videos.filter((a) => a.title !== detailPageData.title);

  useEffect(() => {
    try {
      if (window.document.getElementById('bg-video-player')) {
        videojs('bg-player').pause();
      }
    } catch (e) {
      // do nothing
    }
    if (window.document.getElementById('play-btn')) {
      window.document.getElementById('play-btn').classList.add('focused');
    }
  }, []);

  useEffect(() => {
    const myProgress = getUserVideoProgress(detailPageData.id);
    if (myProgress > 0) {
      setCurrentProgress(Math.ceil(Number(myProgress)));
    } else {
      setCurrentProgress(0);
    }
    setShowButtons(true);
  }, [detailPageData.id]);

  const handlePlayerOpen = (fromProgress) => {
    setShowPlayerFromProgress(fromProgress);
    setShowPlayer(true);
  };
  const handlePlayerClose = () => {
    setShowPlayer(false);
    const myProgress = getUserVideoProgress(detailPageData.id);
    if (myProgress > 0) {
      setCurrentProgress(Math.ceil(Number(myProgress)));
    } else {
      setCurrentProgress(0);
    }
    setShowDetailPage(true);
    if (window.document.getElementById('play-btn')) {
      window.document.getElementById('play-btn').classList.add('focused');
    }
  };

  const handleSwitchDetailPage = (id) => {
    if (window.document.querySelectorAll('.video-details')) {
      if (detailPageData.isPortrait === true) {
        window.document.querySelectorAll(
          '.video-details'
        )[0].style.marginTop = `197px`;
      } else {
        window.document.querySelectorAll(
          '.video-details'
        )[0].style.marginTop = `221px`;
      }
    }
    const element = window.document.querySelectorAll(
      `#${containerIdPrefix}${id}`
    );
    if (element.length > 0) {
      element[0].click();
    }
    if (window.document.getElementById('resume-btn')) {
      window.document.getElementById('resume-btn').classList.remove('focused');
    }
    if (window.document.getElementById('play-btn')) {
      window.document.getElementById('play-btn').classList.add('focused');
    }
  };

  const goBack = () => {
    try {
      if (window.document.getElementById('bg-video-player')) {
        videojs('bg-player').play();
      }
    } catch (e) {
      // do nothing
    }
    if (!showPlayer) {
      handleHideDetailPage();
      setTimeout(() => {
        restoreFocus();
      }, 1000);
    }
  };

  const handleScroll = () => {
    const focusedElements = window.document.querySelectorAll(
      '.prj-element.focused'
    );

    if (focusedElements.length > 0) {
      if (detailPageData.isPortrait === true) {
        window.document.querySelectorAll(
          '.video-details'
        )[0].style.marginTop = `195px`;
      } else if (detailPageData.isPortrait === false) {
        window.document.querySelectorAll(
          '.video-details'
        )[0].style.marginTop = `239px`;
      }
    }
  };

  const handleScrollEle = () => {
    const focusedElements = window.document.querySelectorAll(
      '.prj-element.focused'
    );

    if (focusedElements.length > 0) {
      scrollAppView(focusedElements[0]);
      window.document.querySelectorAll(
        '.video-details'
      )[0].style.marginTop = `-190px`;
    }
  };

  return (
    <DetailLayout>
      <>
        <div
          className="bg-poster"
          style={{
            backgroundImage: ` linear-gradient( rgb(10 9 10 / 31%), rgb(5 5 5 / 79%)), url("${detailPageData.posterH}") `,
          }}
        />
        <button
          type="button"
          className="hide"
          id="video-detail-focus"
          onClick={handleScroll}
        >
          Scroll
        </button>
        <div className="bg-poster-layer" />

        <div
          className="back-to-page prj-element"
          id="back-to-page"
          onClick={goBack}
          aria-hidden
        >
          &lt; &nbsp; Back
        </div>
        <div className="video-details-main-div" id="video-detail-focus">
          <div className="video-details">
            <div className="details">
              <div className="title">{detailPageData.title}</div>
              <div className="video-rating">{detailPageData.rating}</div>
              <div className="description">{detailPageData.description}</div>
              {showButtons && (
                <div className="buttons">
                  {currentProgress !== 0 && (
                    <button
                      id="resume-btn"
                      type="button"
                      className="play-btn prj-element"
                      data-focus-left={false}
                      data-focus-right="#play-btn"
                      data-focus-up={false}
                      data-focus-down=".video.prj-element"
                      data-on-self-focus="#video-detail-focus"
                      onClick={() => {
                        handlePlayerOpen(true);
                      }}
                    >
                      <img src={resumeIcon} alt="resume" />
                      Resume from {convertSecToTime(currentProgress)}
                    </button>
                  )}
                  <button
                    id="play-btn"
                    type="button"
                    className={
                      currentProgress === 0
                        ? 'play-btn small prj-element focused'
                        : 'play-btn prj-element focused'
                    }
                    data-focus-left="#resume-btn"
                    data-focus-right={false}
                    data-focus-up={false}
                    data-focus-down=".video.prj-element"
                    data-on-self-focus="#video-detail-focus"
                    onClick={() => {
                      handlePlayerOpen(false);
                    }}
                  >
                    <img src={playIcon} alt="play" />
                    {currentProgress === 0 ? 'Play' : 'Play from beginning'}
                  </button>
                </div>
              )}
            </div>
            <div className="image">
              <div
                className={`image-wrap ${
                  detailPageData.isPortrait ? 'portrait' : 'landscape'
                }`}
              >
                <img
                  src={
                    detailPageData.isPortrait
                      ? detailPageData.posterV
                      : detailPageData.posterH
                  }
                  alt={detailPageData.title}
                />
              </div>
            </div>
          </div>

          {videos.length > 1 && (
            <div className="more-like-this">
              <button
                type="button"
                className="hide"
                id={scrollHandleButtonId}
                onClick={handleScrollEle}
              >
                Scroll
              </button>
              <div className="horizontal-list more-like-this-horizontal-list">
                <div className="title">More Like This</div>
                <div className="list">
                  <div className="list-container more-like-this-list-container">
                    {moreLikeThis.map((v, idx) => (
                      <div
                        className={`video prj-element media-element ${
                          detailPageData.isPortrait ? 'portrait' : 'landscape'
                        }`}
                        id={`detailitem${idx}v`}
                        key={`more-like-this-${v.id}`}
                        data-focus-left={
                          idx === 0 ? '' : `#detailitem${idx - 1}v`
                        }
                        data-focus-right={
                          idx + 1 === videos.length
                            ? ''
                            : `#detailitem${idx + 1}v`
                        }
                        data-focus-up="#play-btn"
                        // data-focus-down=".video.prj-element"
                        data-on-self-focus={`#${scrollHandleButtonId}`}
                        // data-on-self-focus="#video-detail-focus"
                        onClick={() => handleSwitchDetailPage(v.id)}
                        role="none"
                      >
                        <div className="img">
                          <div className="img-container">
                            <img
                              src={
                                detailPageData.isPortrait
                                  ? v.posterV
                                  : v.posterH
                              }
                              alt={v.title}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {showPlayer && (
          <Player
            id="player"
            videoData={detailPageData}
            resumeFrom={showPlayerFromProgress ? currentProgress : 0}
            handlePlayerClose={handlePlayerClose}
            setShowDetailPage={setShowDetailPage}
          />
        )}
      </>
    </DetailLayout>
  );
};

DetailPage.propTypes = {
  detailPageData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    poster: PropTypes.string,
    posterH: PropTypes.string,
    posterV: PropTypes.string,
    hlsUrl: PropTypes.string,
    isPortrait: PropTypes.bool,
    genres: PropTypes.string,
    duration: PropTypes.number,
    category: PropTypes.string,
    channelId: PropTypes.number,
    director: PropTypes.string,
    actor1: PropTypes.string,
    actor2: PropTypes.string,
    actor3: PropTypes.string,
    rating: PropTypes.string,
    ratingSource: PropTypes.string,
    season: PropTypes.number,
    episode: PropTypes.number,
    srtUrl: PropTypes.string,
    vttUrl: PropTypes.string,
    source: PropTypes.string,
    playDirectUrl: PropTypes.string,
    liveVastUrl: PropTypes.string,
  }).isRequired,
  handleHideDetailPage: PropTypes.func.isRequired,
  setShowDetailPage: PropTypes.func.isRequired,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      shortDescription: PropTypes.string,
      hlsUrl: PropTypes.string,
      poster: PropTypes.string,
      posterH: PropTypes.string,
      posterV: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      duration: PropTypes.number,
      type: PropTypes.string,
      genres: PropTypes.string,
      category: PropTypes.string,
      channelId: PropTypes.number,
      director: PropTypes.string,
      actor1: PropTypes.string,
      actor2: PropTypes.string,
      actor3: PropTypes.string,
      rating: PropTypes.string,
      ratingSource: PropTypes.string,
      season: PropTypes.number,
      episode: PropTypes.number,
      srtUrl: PropTypes.string,
      vttUrl: PropTypes.string,
      source: PropTypes.string,
      playDirectUrl: PropTypes.string,
      liveVastUrl: PropTypes.string,
    })
  ).isRequired,
  containerIdPrefix: PropTypes.string.isRequired,
};

export default DetailPage;
